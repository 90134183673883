<template>
  <div class="space-y-4">
    <asom-modal
      title="Create Shift End Summary"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
    <asom-card>
      <form>
        <asom-alert
          class="col-span-2"
          v-if="error"
          variant="error"
          :error-message="error"
        />
        <div class="pt-8">
          <p class="pb-2 font-sans font-semibold text-gray-500">
            Manual Change Shift End Summary
          </p>
          <div
            class="w-96 relative rounded-lg bg-gray-100 px-6 py-5 shadow items-center"
          >
            <div class="p-2 h-10 w-10 bg-button rounded-md">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <div class="pt-4">
              <p class="font-sans font-bold text-gray-500 text-3xl">
                {{ formatCurrency(total) }}
              </p>
              <p class="font-sans font-semibold text-gray-500">
                Total Amount
              </p>
            </div>
          </div>
        </div>

        <div class="col-span-2 flex justify-end space-x-4">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="router.push({ name: 'Cash Float e-Log' })"
          />
          <asom-button
            text="Declare Cash Bag"
            variant="secondary"
            @click="$router.push({ name: 'Cash Declaration Form Create' })"
          />
          <asom-button
            text="Confirm Shift End"
            @click="submitClicked"
            :loading="isSubmitting"
          />
        </div>
      </form>
    </asom-card>
    <div class="pt-6">
      <div>Certis Bag Logs</div>
      <div class="p-4">Current Shift: {{ shift }}</div>
      <asom-client-table
        :columns="[
          'transactionNo',
          'createdBy',
          'dateCreated',
          'time',
          'certisBagNo',
          'amount',
          'transactionType',
        ]"
        :data="transactionLogs"
        :sortableColumns="[
          'transactionNo',
          'createdBy',
          'certisBagNo',
          'amount',
          'transactionType',
        ]"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_createdBy>Created By</template>
        <template v-slot:header_certisBagNo>Certis Bag No</template>
        <template v-slot:header_transactionType>Transaction Type</template>
        <template v-slot:header_transactionNo>Transaction No</template>
        <template v-slot:header_dateCreated>Date</template>
        <template v-slot:dateCreated="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:time="scopedSlots">
          <span>{{ displayUtcTime(scopedSlots.rowData) }}</span>
        </template>
        <template v-slot:transactionNo="scopedSlots">
          <span class="text-button underline">
            {{ scopedSlots.data }}
          </span>
        </template>
      </asom-client-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import filter from "lodash.filter";
import moment from "moment";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { parseDateTime } from "@/helpers/dateTimeHelpers";
import {
  getCertisBagTransactions,
  createShiftEndSummary,
} from "../../../../services/cashManagement.service";
import { formatCurrency } from "../../../../helpers/numbers";
import { psc } from "../../../../constants/APIEnums/cashManagementEnums";

export default {
  name: "PETShiftEndSummary",
  data() {
    return {
      showModal: false,
      transactionLogs: [],
      transactionLogIds: [],
      isSubmitting: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      shift: "currentRoster/currentRosterShift",
    }),
    total() {
      let total = 0;
      if (this.$route.query) {
        total = this.$route.query.totalAmount
          ? this.$route.query.totalAmount
          : 0;
      }
      return Number(total);
    },
    getTableData() {
      return this.psmLogs;
    },
  },
  mounted() {
    this.getCertisBagTransactions();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getCertisBagTransactions();
    },
  },
  methods: {
    formatCurrency,
    displayUtcDate,
    displayUtcTime,
    async getCertisBagTransactions() {
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const resp = await getCertisBagTransactions({
        accountId: this.pscId,
        startDate: parseDateTime(moment().subtract(3, "days"), false),
        endDate: parseDateTime(moment().add(3, "days"), false),
      });
      if (resp.success) {
        this.transactionLogs = filter(get(resp, "payload.list", []), {
          addedToShiftEnd: false,
          transactionTypeEnum:
            psc.cashFloatELog.CERTIS_BAG_TRANSACTION_TYPE.OPEN.VALUE,
        });
        this.transactionLogIds = this.transactionLogs.map(
          (log) => log.transactionId
        );
        this.isLoading = false;
      } else {
        return;
      }
    },
    submitClicked() {
      this.showModal = true;
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createShiftEndSummary({
        shiftEndSummaryType: 3,
        psmMachineAmount: 0,
        psmTransactionIds: [],
        preEncodedTicketTransactionIds: [],
        cashFloatTransactionIds: this.transactionLogIds,
        attachmentIds: [],
        accountId: this.pscId,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      });
      this.isSubmitting = false;
      this.showModal = false;
      if (result.success) {
        this.$router.push({ name: "Cash Float e-Log" });
      } else {
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
